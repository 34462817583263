<template>
  <v-row justify="center">
    <v-dialog v-model="isUpdateDateModalOpen" persistent max-width="360">
      <v-card>
        <v-card-title class="text-h4 font-weight-medium">
          {{ $t("supplyOrders.update_delivery_date") }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scroll-y-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  outlined
                  label="Delivery date"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  :rules="[
                    (value) => !!value || 'Required.',
                    (value) => {
                      return (
                        new Date().getTime() <
                          new Date(parseDate(value)).getTime() ||
                        'Date should be superior to the current date'
                      );
                    },
                  ]"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                class="my-0 py-2"
                v-model="date"
                no-title
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            text
            @click="toggleUpdateDate"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary"
            text
            @click="UpdateDate()"
            :loading="isLoading"
          >
            {{ $t("supplyOrders.update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    isUpdateDateModalOpen: Boolean,
    toggleUpdateDate: Function,
    // currentDeliveryDate: Object,
    currentOrderItem: Object,
  },
  data() {
    return {
      valid: false,
      menu: null,
      date: null,
      dateFormatted: null,
      note: "",
      isLoading: false,
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    console: () => console,
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async UpdateDate() {
      this.validate();
      this.currentOrderItem.expected_delivery_date = this.date;
      if (this.valid) {
        await this.$store.dispatch(
          "supplyOrders/updateDeliveryDate",
          this.currentOrderItem,
          {
            supply_order_id: this.currentOrderItem.id,
          }
        );
      }
      this.toggleUpdateDate();
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  // mounted() {
  //   if (this.currentOrderItem) {
  //     this.date = this.currentOrderItem.expected_delivery_date;
  //   }
  // },
};
</script>